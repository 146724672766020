exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-become-our-vip-js": () => import("./../../../src/pages/become-our-vip.js" /* webpackChunkName: "component---src-pages-become-our-vip-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contact-confirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-does-it-work-js": () => import("./../../../src/pages/how-does-it-work.js" /* webpackChunkName: "component---src-pages-how-does-it-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-pl-faq-js": () => import("./../../../src/pages/pl/faq.js" /* webpackChunkName: "component---src-pages-pl-faq-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-jak-to-dziala-js": () => import("./../../../src/pages/pl/jak-to-dziala.js" /* webpackChunkName: "component---src-pages-pl-jak-to-dziala-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */),
  "component---src-pages-pl-o-nas-js": () => import("./../../../src/pages/pl/o-nas.js" /* webpackChunkName: "component---src-pages-pl-o-nas-js" */),
  "component---src-pages-pl-polecaj-znajomemu-js": () => import("./../../../src/pages/pl/polecaj-znajomemu.js" /* webpackChunkName: "component---src-pages-pl-polecaj-znajomemu-js" */),
  "component---src-pages-pl-polityka-aml-js": () => import("./../../../src/pages/pl/polityka-aml.js" /* webpackChunkName: "component---src-pages-pl-polityka-aml-js" */),
  "component---src-pages-pl-polityka-prywatnosci-js": () => import("./../../../src/pages/pl/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-pl-polityka-prywatnosci-js" */),
  "component---src-pages-pl-potwierdzenie-kontaktu-js": () => import("./../../../src/pages/pl/potwierdzenie-kontaktu.js" /* webpackChunkName: "component---src-pages-pl-potwierdzenie-kontaktu-js" */),
  "component---src-pages-pl-potwierdzenie-vip-js": () => import("./../../../src/pages/pl/potwierdzenie-vip.js" /* webpackChunkName: "component---src-pages-pl-potwierdzenie-vip-js" */),
  "component---src-pages-pl-regulamin-js": () => import("./../../../src/pages/pl/regulamin.js" /* webpackChunkName: "component---src-pages-pl-regulamin-js" */),
  "component---src-pages-pl-zostan-naszym-vipem-js": () => import("./../../../src/pages/pl/zostan-naszym-vipem.js" /* webpackChunkName: "component---src-pages-pl-zostan-naszym-vipem-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recommend-to-a-friend-js": () => import("./../../../src/pages/recommend-to-a-friend.js" /* webpackChunkName: "component---src-pages-recommend-to-a-friend-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-vip-confirmation-js": () => import("./../../../src/pages/vip-confirmation.js" /* webpackChunkName: "component---src-pages-vip-confirmation-js" */)
}

